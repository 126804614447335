// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-chronoblog-src-pages-404-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-pages-tags-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/pages/tags.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-pages-tags-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-templates-post-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-templates-tag-template-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/templates/tag-template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-templates-tag-template-js" */),
  "component---src-pages-blog-2021-02-07-test-md": () => import("./../src/pages/blog/2021-02-07-test.md" /* webpackChunkName: "component---src-pages-blog-2021-02-07-test-md" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */)
}

